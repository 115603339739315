<template>
  <div class="payment-services">
    <span>Servizio momentaneamente</span>
    <span>sospeso per mancato pagamento</span>
    <div class="activate">
      <span>Contattare l'amministrazione per </span>
      <span>riattivarlo</span>
    </div>
    <span>800 911924</span>
  </div>
</template>

<script>
export default {
  name: 'Error',
};
</script>

<style scoped>
.payment-services {
  background-image: linear-gradient(
    162.43deg,
    #000000,
    #1c022c,
    #200230,
    #32054d,
    #370654,
    #530d7e,
    #5c108c
  );
  color: white;
  font-family: 'Avenir Next';
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
}

.activate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 60px 0;
}
</style>
